@use 'sass:math';
main {
  display: block;
  background: #fafafa;
  margin: 0;
  padding: math.div($gutter, 2) 0;

  min-height: 50vh;

  @include media(tablet) {
    padding: 15px 0;
    min-height: 74vh;
  }
}

.breadcrumbs {
  display: none;
  color: $black;

  @include media(tablet) {
    display: block;
    margin: 0;
    margin-top: 0;
    margin-bottom: -$gutter * 0.67;
    font-size: 14px;

    .breadcrumbs-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .breadcrumbs-link {
      text-decoration: none;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .breadcrumbs-list-item {
      display: inline-block;
      position: relative;
      margin-left: math.div($gutter, 3);
      padding-left: math.div($gutter, 4);
      margin-bottom: math.div($gutter, 6);

      &:before {
        content: "/";
        color: $black;
        display: block;
        position: absolute;
        top: 3px;
        left: -4px;
        margin: auto 0;
      }

      &:first-child {
        margin-left: 0;
        padding-left: 0;

        &:before {
          display: none;
        }
      }
    }
  }
}
.modal{
  display: block;
  height: 100vh;
  background: rgba(0,0,0, 0.7);
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
  overflow: hidden;
}
.modal-dialog{
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 24px;
  min-width: 400px;
  max-width: 400px;
  border-radius: 2px;
  button{
    margin-left: 0px;
  }
  .heading-large{
    margin-bottom: 30px;
  }
  p {
    font-size: 19px;
  }
}
.sign-up-page{
  .error-summary{
      margin-top: 0;
  }
}
#id-section-help-initialPassword {
  width: 50%;
}
#content-activity-popup{
  .form-group{
    .form-control-1-16{
      label{
        font-size: 19px;
    }
    }
    .form-control-1-10{
      label{
        font-size: 19px;
      }
    }
    .form-label-b19{
      font-size: 24px;
      font-family: Roboto, "Helvetica Neue", sans-serif;
      font-weight: 500;
    }
  }
  #id-endDate{
    .form-group{
      margin-bottom: 5px;
    }
  }
}
#generate_cv {
  .inlineRadioButtons {
    display: inherit;
  }
}
#myCvUpload {
  .inlineRadioButtons {
    display: block;
    margin-bottom: 30px;
  }
}

#planPathwayDescription{
  .selectOptions{
    button.column-fifth{
      height: 5em!important;
    }
  }
} 
/*for track based on age*/
.track-young{
  display: none;
}
.showTrack{
  display: none;
}
.track-adult{
  display: inline-block;
  @media (max-width: 900px) {
    display: block;
  }
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.hiddenForAT {
  visibility: hidden;
}
/*style for chatbot-rasa*/
.rw-conversation-container {
  .rw-header{
    background-color: #000000!important;
    .rw-avatar{
      left: 8px!important;
    }
  }
  .rw-messages-container{
    background: #F7C047;
    .rw-message {
      .rw-client{
        background-color: #fff!important;
        border: 2px solid #000;
        color: #000!important;
        border-radius: 15px 15px 0px 15px!important;
      }
    }
  }
  .rw-response{
    background-color: #fff!important;
    border: 2px solid #000;
    color: #000!important;
    border-radius: 15px 15px 15px 0px!important;
    ul{
      height: auto;
      li{
        margin-left: 0;
      }
    }
  }
  .rw-avatar{
    width: 40px!important;
    height: 40px!important;
  }
}
.rw-launcher{
  background-color: #1FAEE4!important;
  width: 80px!important;
  height: 80px!important;
  background-image: url(../../assets/images/chatbot-icon-55.png);
  background-repeat: no-repeat;
  display: block;
  background-position: center;
  .rw-open-launcher, .rw-close-launcher {
    display: none;
  }
}
.rw-sender .rw-send .rw-send-icon-ready{fill: #E8BC60!important;}
.removeChatbot{
  #rasaWebchatPro{
    display: none;
  }
}
.rw-group-message.rw-from-response span.rw-message-date, .rw-group-message.rw-from-client span.rw-message-date{
  color: #000!important;
}
.rw-conversation-container {
  background: #F7C047;
  .rw-sender{
    border-radius: 40px;
    background-color: #fff;
    border: 2px solid #000;
    margin: 15px;
    padding: 5px 5px 5px 20px!important;
    .rw-send{
      background-image: url(../../assets/images/right-arrow.png);
      background-repeat: no-repeat;
      display: block;
      background-position: center;
      width: 60px;
      height: 50px;
      border-radius: 20px;
      svg{
        display: none!important;
      }
    }
    textarea{
      border: none;
      box-shadow: none;
      border-bottom: 2px solid #333;
      margin-left: 2px!important;
      &:focus{
        border: none;
        box-shadow: none;
      }
    }
  }
  .rw-close-button{
    // background-image: url(../../assets/images/close.png);
    // background-repeat: no-repeat;
    display: block!important;
    // background-position: center;
    background-color: #fff!important;
    width: 45px!important;
    height: 45px!important;
    border-radius: 25px;
    padding: 0!important;
    &::after{
      content: 'x';
      font-size: 35px;
      display: block;
      margin-top: -8px!important;
    }
    img{
      display: none!important;
    }
  }
}
//Changes for angular material style
.mat-card {
  margin-bottom: 24px;
  padding: 24px;
  .mat-card-content{
    margin-top: 20px;
  }
  .mobile-label {
    font-size: 14px;
    color: #61A6FF;
    font-weight: 600;
  }
  .spacer {
    flex: 1 1 auto;
  }
}

.mat-icon-button, .mat-button.mat-accent, 
.mat-icon-button.mat-accent,  .mat-stroked-button.mat-accent, 
.mat-icon-button.mat-primary, .mat-icon-button.mat-primary, 
.mat-stroked-button.mat-primary{
  color: #61A6FF;
}
.mat-paginator-range-label {
  margin: 0px !important;
}
.mat-cell {
  font-size: 14px;
  padding-right: 12px;
  margin: 12px 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
.card-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: calc(50% - 12px);
  .mat-form-field{
    width: 100%;
  }
}
.mat-card {
  margin-bottom: 24px !important;
  padding: 24px !important;
}
.mat-card .mat-card-content p {
  margin: 12px 0 ;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.label-hr {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 16px 0;
  color: #61A6FF;

  span {
    font-size: 20.8px;
    font-weight: 100;
    padding-right: 8px;
    color: #61A6FF;
  }

  &:after {
    content: "";
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  }
  &:before {
    content: "";
    width: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  }

  &:after {
    margin-left: 0.25em;
  }
}
.mat-card .mat-card-footer {
  display: flex;
  margin: 0;
}
.mobile-label {
  display: none;
}
.mat-cell {
  font-size: 14px;
  padding-right: 12px;
  margin: 12px 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
.mat-header-cell {
  padding-right: 12px;
}
//chatbot style
#rasaWebchatPro {
  position: fixed;
  top: 65px;
  right: 0;
  bottom: auto;
  .rw-widget-container {
    position: relative;
    top: auto;
    bottom: auto;
    padding-top: 84px;
    .rw-launcher {
      position: absolute;
      top: -4px;
      @media screen and (max-width: 749px){
        top: 0px;
      }
    }
  }
  .rw-widget-container.rw-full-screen {
    position: fixed;
    top: 0;
    padding-top: 0;
    .rw-launcher {
      top: 68px;
    }
  }
  .rw-launcher {
    background-color: #fff!important;
  }
  .rw-conversation-container .rw-avatar {
    width: 40px !important;
    height: 40px !important;
    border-radius: 100%;
    margin-right: 6px;
    position: relative;
    bottom: 5px;
  }
  .rw-conversation-container .rw-header.rw-with-subtitle .rw-avatar {
    top: 0px;
}
}
.mat-flat-button{
  background-color: #61a6ff!important;
  color: #fff;
}
.mat-snack-bar-container {
  color: rgba($color: #fff, $alpha: 0.97);
  background: rgba($color: #61a6ff , $alpha: 0.97);
  .mat-simple-snackbar-action {
    color: rgba($color: #fff, $alpha: 0.97);
    background: rgba($color: #61a6ff , $alpha: 0.97);
  }
}
// breadcrumb section
.custom-bread-crumb {
  background-color: transparent !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
  height: fit-content !important;
  .line {
    padding-right: 0px !important;
    margin: 0 6px !important;
  }
  .ng-star-inserted {
    font-size: 16px !important;
    color: #ffffff !important;
    align-items: center;
    word-break: break-word;
    white-space: normal;
    text-align: left;
  }
  span:nth-last-child(1) {
    font-weight: 500;
    color: #ffffff !important;
  }
}
.mat-typography p {
  margin-bottom: 20px!important;
}
.mat-card-title {
  line-height: 1.2;
}
.avatar {
  display: flex;
  justify-content: center;
  img {
    height: 160px;
  }
}
.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 0.75em 0 0.75em;
  margin-top: -0.25em;
  position: relative;
  width: 250px !important;
}
.mat-icon-button{
  color: inherit !important;
}
table td ,table th{
  border-bottom: none !important;
    font-size: 13px !important;
}
.avatar {
  display: flex;
  justify-content: center;
  img {
    height: 160px;
  }
}

.mat-calendar-body-selected {
  background-color: #1faee4 !important;
  color: white;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical)
{
  background-color: rgba(31, 174, 228, 0.3);
}
.seq-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  background-color: rgba(31, 174, 228, 0.3);
}
.cdk-program-focused .mat-calender-body-active > 
.mat-calender-body-cell-content :not(.mat-calender-body-selected):not(.mat-calender-body-comparision-identical)
{
  background-color: rgba(31 ,174, 228, 0.3) !important;
}

.mat-icon-button.mat-button-disabled.mat-button-disabled{
  color: rgba(0, 0, 0, 0.26) !important;
}
.removeAvatar{
  .avtar-placement{
    display: none;
  }
}
.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7) !important;
  background: #61a6ff !important;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%) !important;
}

.mat-simple-snackbar-action {
  color: #ffffff !important;
}
